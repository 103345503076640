import React, { useState } from 'react';
import { Container, Nav, Navbar } from "react-bootstrap";
import './Header.css';
import zincLogo from '../../util/zincLogo.png';


const Header = ({ store }) => {
    
    const [expanded, setExpanded] = useState(false);

    const handleExpanded = () => {
        setExpanded(false);
    }

    return (
        <Navbar expand="lg" expanded={expanded} variant="dark" className="header">
            <Container>
                <div className="mobile">
                    <Navbar.Toggle className="mobile-left" onClick={() => setExpanded(!expanded)} />
                    <Nav className="mobile-center-logo">
                        <Nav.Link className="nav-c-link" href="#HOME" onClick={handleExpanded} >
                            <img alt="" src={zincLogo} className="zincLogo" />
                        </Nav.Link>
                    </Nav>
                </div>
                <Navbar.Collapse id="responsive-navbar-nav" className="mobile_left_logo">
                    <Nav >
                        <Nav.Link className="nav-a-link" href="#HOME" onClick={handleExpanded} ><img alt="" className="taboo3" src={`${process.env.REACT_APP_IMAGE_URL}${store.icon_image_path}`} /></Nav.Link>
                    </Nav>
                    <Nav>
                        <Nav.Link className="nav-a-link" href="#ABOUT" onClick={handleExpanded}>ABOUT</Nav.Link>
                    </Nav>
                    <Nav>
                        <Nav.Link className="nav-a-link" href="#SERVICE" onClick={handleExpanded}>SERVICE</Nav.Link>
                    </Nav>

                    <Nav className="mobileShow">
                        <Nav.Link className="nav-b-link" href="#HOME" onClick={handleExpanded} > <img alt="" src={zincLogo} width="100" height="100" /></Nav.Link>
                    </Nav>

                    <Nav>
                        <Nav.Link className="nav-a-link" href="#MENU" onClick={handleExpanded} >MENU</Nav.Link>
                    </Nav>
                    <Nav>
                        <Nav.Link className="nav-a-link" href="#GALLERY" onClick={handleExpanded} >GALLERY</Nav.Link>
                    </Nav>
                    <Nav>
                        <Nav.Link className="nav-a-link" href="#CONTACT" onClick={handleExpanded} >CONTACT</Nav.Link>
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    )
}

export default Header;