import { useState, useEffect } from 'react';
import './App.css';
import '../Home/Home';
import Home from '../Home/Home';
import Header from '../Header/Header';
import About from '../About/About';
import Menu from '../Menu/Menu';
import Gallery from '../Gallery/Gallery';
import Contact from '../Contact/Contact';
import Footer from '../Footer/Footer';
import axios from 'axios';
import whatsappIcon from '../../util/whatsappIcon.png';
import fbmessagerIcon from '../../util/fbmessagerIcon.png';



function App() {

	const [data, setData] = useState()
	const [menus, setMenus] = useState()
	const [venues, setVenues] = useState()
	const [store, setStore] = useState()
	const [banners, setBanners] = useState()
	console.log('store',store)
	useEffect(() => {
		fetchData();
	}, [])

	const fetchData = async () => {
		await axios.get(`${process.env.REACT_APP_SERVICE_URL}profile?store_id=2`)
			.then(response => {
				setData(response.data)
				setMenus(response.data.menus)
				setStore(response.data.store)
				setVenues(response.data.venues)
				setBanners(response.data.banners)
			})
			.catch(error => {
				console.log(error)
			})
	}

	return (
		<div className="App" style={{ position: "relative" }}>
			{store != null ?
				<Header store={store} />
				: null}
			<section id="HOME">
				{banners != null ? <Home banners={banners} store={store} />
					: null}
			</section>
			<section id="ABOUT">
				{store != null ?
					<About store={store} />
					: null}
			</section>
			{menus != null ?
				<Menu data={data} />
				: null}
			{data != null ?
				<section id="GALLERY">
					<Gallery data={data} />
				</section>
				: null}
			<section id="CONTACT">
				{store != null ?
					<Contact store={store} />
					: null}
			</section>
			{store != null ?
				<Footer {...data} />
				: null}
			{
				store != null ?
					<div className="home-icons">
						<a target="_blank" href={process.env.REACT_APP_WHATSAPP_URL + store.whatsapp} className="social-link" >
							<img className="social-btn" src={whatsappIcon} alt="" />
						</a>
						<a target="_blank" href={process.env.REACT_APP_WHATSAPP_URL + store.whatsapp} style={{ textDecoration: "none" }}>
							<h6 className="social-text">whatsapp</h6>
						</a>
						<a href={store.facebook_messenger} target='_blank' className="social-link">
							<img className="social-btn" src={fbmessagerIcon} alt="" />
						</a>
						<a href={store.facebook_messenger} target='_blank' style={{ textDecoration: "none" }}>
							<p className="social-text">facebook</p>
						</a>
					</div>
					: null}
		</div>
	);
}

export default App;
