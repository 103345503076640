import React from 'react';
import './Contact.css';
import taboo3 from '../../util/taboo3.png';
import instagramIcon from '../../util/instagramIcon.png';
import facebookIcon from '../../util/facebookIcon.png';

const Contact = ({ store }) => {
    return (
        <div className="Contact pt-5">
            <h1 className="contact-title">Contact Us</h1>
            <div className="contact-content">
                <iframe title="Taboo-location" src={store.google_map} id="contact-map"></iframe>
                <div className="contact-right">
                    <img id="tabooLogo2" src={`${process.env.REACT_APP_IMAGE_URL}${store.icon_image_path}`} alt="" />
                    <h6 className="contact-text" ><b>Tel:</b> <a target="_blank" href={'tel:'+store.phone}>{store.phone} </a></h6><br />
                    <div className="">
                        <h6 className="contact-text" ><b>Opening Hours:</b>&nbsp;</h6>
                        <h6 className="contact-text">{store.working_hour}</h6>
                    </div>
                    <br />
                    <h6 className="contact-text"><b>Address:</b><br />{store.address}</h6>
                </div>
                <div className="contact-mobile-right">
                    <img alt="" src={taboo3} className="contact-mobile-taboo" />
                    <div className="contact-mobile-right-texts">
                        <p className="contact-mobile-right-text">Address:</p>
                        <p className="contact-mobile-right-text">{store.address}</p>
                        <p className="contact-mobile-right-text"></p>
                    </div>
                    <div className="contact-mobile-right-texts">
                        <p className="contact-mobile-right-text">Tel:</p>
                        <p className="contact-mobile-right-text"><a target="_blank" href={'tel:'+store.phone} >{store.phone} </a></p>
                    </div>
                    <div className="contact-mobile-right-texts">
                        <p className="contact-mobile-right-text">Opening Hours:</p>
                        <p className="contact-mobile-right-text">{store.working_hour}</p>
                        {/* <p className="contact-mobile-right-text">Sun: 8am-2am</p> */}
                    </div>

                    <div className="contact-mobile-bottom">
                        <h3 className="contact-mobile-bottom-title">Stay In Touch</h3>
                        <p className="contact-mobile-bottom-text">Join Us</p>
                        <p className="contact-mobile-bottom-text">Subscribe</p>
                        <div className="contact-mobile-bottom-icons">
                            <a href={store.ig_link} className="mobile-social-link">
                                <img alt="" src={instagramIcon} className="contact-mobile-bottom-icon" />
                            </a>
                            <a href={store.facebook_link} className="mobile-social-link">
                                <img alt="" src={facebookIcon} className="contact-mobile-bottom-icon" />
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            <div className="contact-bottom">
                <a href="#HOME">
                    <img id="tabooLogo3" src={`https://api1.uniconsults.com/storage/${store.icon_image_path}`} alt="" />
                </a>
                <div className="contact-bottom-right">
                    <a href="#ABOUT" className="contact-bottom-right-button">ABOUT</a>
                    <a href="#SERVICE" className="contact-bottom-right-button">SERVICE</a>
                    <a href="#MENU" className="contact-bottom-right-button">MENU</a>
                    <a href="#GALLERY" className="contact-bottom-right-button">GALLERY</a>
                    <a href="#CONTACT" className="contact-bottom-right-button">CONTACT</a>
                </div>
            </div>
        </div>
    )
}

export default Contact;