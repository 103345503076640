import React, { useState, useEffect } from 'react';

import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css'; // This only needs to be imported once in your app


import './Menu.css';

const Menu = ({ data }) => {
    const {menus} = data
    const {menuCategories} = data
    const [tag, setTag] = useState(menuCategories[0].name)
    const [filteredImages, setFilteredImages] = useState(null)

    const [photoIndex, setPhotoIndex] = useState(0);
    const [isOpen, setIsOpen] = useState(false);
  

    useEffect(() => {
        setFilteredImages(menus.filter(menu => menu.category.name === tag))
        console.log('filteredImages', filteredImages)
    }, [tag])

    return (
        <div className="Menu">
            <section id="MENU"></section>
            <h1 className="menu-title">Menu</h1>
            <div className="menus my-3">
                {
                    menuCategories.map((item,index) =>
                        <h6 key={item.id} className={
                            "menu "+`${tag === item.name ? 'active' : '' }`} onClick={() => setTag(item.name)}>{item.name}</h6>
                    )
                }

            </div>
 
            <div className="menu-photos row  m-0 justify-content-center">
                {filteredImages != null ? filteredImages.map((menu,index) => (
                    <div key={menu.id} className="image col-12 col-sm-6" onClick={() => {
                        setPhotoIndex(index)
                        setIsOpen( true )
                      }
                    }>
                        <img className="w-100" src={`${process.env.REACT_APP_IMAGE_URL}${menu.image}`} alt="menuPhoto" />
                        {/* <div className="image__overlay">
                            <div className="image__title">{menu.menu_title}</div>
                            <p className="cost">${menu.price}</p>
                        </div> */}
                    </div>
                )) : null}
            </div>
         

            {isOpen && (
                <Lightbox
                    mainSrc={`${process.env.REACT_APP_IMAGE_URL}${filteredImages[photoIndex]['image']}`}
                    onImageLoad={() => {
                        window.dispatchEvent(new Event('resize'));
                    }}
                    nextSrc={`${process.env.REACT_APP_IMAGE_URL}${filteredImages[(photoIndex + 1) % filteredImages.length]['image']}`}
                    prevSrc={`${process.env.REACT_APP_IMAGE_URL}${filteredImages[(photoIndex + filteredImages.length - 1) % filteredImages.length]['image']}`}
                    onCloseRequest={() => setIsOpen(false)}
                    onMovePrevRequest={() =>
                        setPhotoIndex(
                            (photoIndex + filteredImages.length - 1) % filteredImages.length,
                        )
                    }
                    onMoveNextRequest={() =>
                        setPhotoIndex(
                            (photoIndex + 1) % filteredImages.length,
                        )
                    } 
                    imageCaption={<div style={{ padding: '16px', margin:'auto' }}>{filteredImages[photoIndex]['menu_content']}</div>}
                ></Lightbox>
                )}
        </div>
    )
}

export default Menu;