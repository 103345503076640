import React from 'react';
import './Footer.css';
import instagramIcon from '../../util/instagramIcon.png';
import facebookIcon from '../../util/facebookIcon.png';

const Footer = (store) => {
  console.log('ff', store)
  return (
    <div className="Footer">
      <div className="footer-left">
        <p className="footer-copyright-top">
        </p>
        <div
          dangerouslySetInnerHTML={{ __html: `${store.store.footer_text}` }}
        />
        <p className="footer-copyright-bottom">
          © Zinc Group all rights reserved
        </p>
      </div>
      <div className="footer-right">
        <div className="row rowwidth pb-3">
          <h3>Our Venues</h3>
          {store.venues != null ? (
            store.venues.map((venue) => {
              return (
                <div className="col-6" key={venue.id}>
                  <a className="linkdeco" href={venue.website}>
                    {venue.name}
                  </a>
                </div>
              );
            })
          ) : (
            <></>
          )}
        </div>
        <div className="footer-right3">
          <h3 style={{ fontFamily: 'Playfair Display' }}>Stay In Touch</h3>
          <p className="footer-right3-text">Join Us</p>
          <p className="footer-right3-text">Subscribe</p>
          <div className="footer-right3-logo">
            <a href={store.store.ig_link} style={{ "margin-right": "1rem" }}>
              <img className="footer-logo" src={instagramIcon} alt="footerLogo" />
            </a>
            <a href={store.store.facebook_link}>
              <img className="footer-logo" src={facebookIcon} alt="footerLogo" />
            </a>
          </div>
        </div>
      </div>
      <div className="mobile-footer">
        <div className="footer-mobile-middle pb-3 row">
          <h3 className="footer-mobile-title1 pt-3">Our Venues</h3>
          {store.venues != null ? (
            store.venues.map((venue) => {
              return (
                <div className="col-6" key={venue.id}>
                  <a className="linkdeco" href={venue.website}>
                    {venue.name}
                  </a>
                </div>
              );
            })
          ) : (
            <></>
          )}
        </div>
        <div className="mobile-footer-bottom">

          <p
            dangerouslySetInnerHTML={{ __html: `${store.store.footer_text}` }}
          />
          <p>© Zinc Group all rights reserved</p>
        </div>
      </div>
    </div>
  )
}

export default Footer;