import React from 'react';
import './Home.css';
import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css";

const Home = ({ banners }) => {
    return (
        <Carousel infiniteLoop={true} autoPlay={true} showThumbs={false} showArrows={false} emulateTouch={true}>
            {banners === undefined ? "" : banners.map(banner => (
                <div className="Home" key={banner.id} style={{ backgroundImage: `url(${process.env.REACT_APP_IMAGE_URL}${banner.banner_image_path})` }}>
                    <div className="layer">
                        <div className="flex">
                            <h1 className="homeTitle">{banner.banner_main_title}</h1>
                        </div>
                    </div>
                </div>
            ))}
        </Carousel>
    )
}

export default Home;