import React, { useState, useEffect } from 'react';
import './Gallery.css';
import Slider from "react-slick";

const Gallery = ({ data }) => {

    const [galleries, setGalleries] = useState()

    useEffect(() => {
        setGalleries(data.galleries);
    }, [])

    const breakPoints = [
        { width: 1, itemsToShow: 1 },
        { width: 550, itemsToShow: 2 },
        { width: 1350, itemsToShow: 3 },
        { width: 1700, itemsToShow: 4 }
    ]

    const rowSlider = {
        dots: true,
        infinite: true,
        speed: 500,
        autoplay: true,
        autoplaySpeed: 3000,
        slidesToShow: 4,
        slidesToScroll: 1,
        initialSlide: 0,
        arrows: false,
        focusOnSelect: true,
        responsive: [
          {
            breakpoint: 1350,
            settings: {
              slidesToShow: 3,
            }
          },
          {
            breakpoint: 992,
            settings: {
              slidesToShow: 2,
            }
          },
          {
            breakpoint: 680,
            settings: {
              slidesToShow: 1,
            }
          }
        ]
      };

    return (
        <div className="Gallery">
            <h1 className="gallery-title">Gallery</h1>
            <div className="gallery-photos">
                <Slider {...rowSlider} className="ownGallerySlider">
                    {galleries != null ? galleries.map((item) => {
                        return (
                            <div key={item.id} className="image1">
                                <img src={`${process.env.REACT_APP_IMAGE_URL}${item.image}`} alt="galleryPhoto" number="1" draggable="false" />
                                <div className="image__overlay1">
                                </div>
                            </div>
                        )
                    })
                        : null}
                </Slider>
            </div >
        </div >
    )
}

export default Gallery;