import React from 'react';
import './About.css';
import aboutImage1 from '../../util/aboutImage1.jpg';
import aboutImage2 from '../../util/aboutImage2.png';
import aboutImage3 from '../../util/aboutImage3.png';
import mobileAboutImage1 from '../../util/mobile-about-image1.png';

const About = (store) => {
    console.log('store',store.store)
    return (
        <div className="about">
            <div className="about-top">
                <div className="about-top-left">
                    <h1 className="title" id="about-title">About Us</h1>
                    {<p className="about-top-description" dangerouslySetInnerHTML={{__html: store.store.about_text}}></p> }
                    {/* <p className="about-top-description">Taboo entertains Wan Chai revelers. With the strap line of "Sinfully Good" Taboo is a lively pub/ bar that has live music as well as dance floor music in between live sessions.</p>
                    <p className="about-top-description" id="about-top-description2">Taboo offers buzzing vibe, friendly atmosphere and big screen TV, if it is Sport you are after with your pint.</p> */}
                </div>
                <div className="about-top-right">
                    <img alt="" src={aboutImage1} id="about-image1" />
                    <img alt="" src={mobileAboutImage1} id="mobile-about-image1" />
                </div>
            </div>
            <p className="about-top-description" id="mobile-about-top-description2">Taboo offers buzzing vibe, friendly atmosphere and big screen TV, if it is Sport you are after with your pint.</p>
            <div className="about-middle">
                <div className="about-middle-left"></div>
                <section id="SERVICE"></section>
                <div className="about-middle-right">
                    <h1 className="title" id="about-title2">Unique gathering place</h1>
                    <h6 className="about-middle-description">Located at the heart of Wanchai. Taboo is a unique gathering place in Wanchai for locals and expatriates. By providing exemplary service. nightly live music, and DJ music Taboo Wanchai has establishes itself as a premier venue in Wanchai.</h6>
                </div>
            </div>
            <div>
                <div className="about-bottom">
                        <img alt="" id="about-image2" src={aboutImage2} />
                    <div className="about-bottom-left">
                    </div>
                    <div className="about-bottom-right">
                        <h1 className="mobile-about-title2">Longest Happy Hour Between 12-9pm</h1>
                        <img id="about-image3" src={aboutImage3} alt="" />
                        <h1 className="title" id="about-title3">Longest Happy Hour Between 12-9pm</h1>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default About;